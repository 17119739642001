<script>
import AuthService from "@/api/services/auth.service.js";
import S3Service from "@/api/services/s3.service";
import UserService from "@/api/services/user.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import VueTagsInput from "@johmun/vue-tags-input";
import axios from "axios";
import { maxLength, required } from "vuelidate/lib/validators";
import { hasDuplicates } from "../../shared/util/helpers";
import { datafileInputObject } from "./upload-help-objects";
import DataImportService from "../../api/services/dataImport.service.js";
import Multiselect from "vue-multiselect";
import DatafileService from "../../api/services/datafile.service.js";

// custom validator for datafile Data Record Start Row
const greterThanHeaderRow = (value, datafileHeaderRow) => {
  return value > datafileHeaderRow.datafileHeaderRow;
};

/**
 * Upload Dataset step 1
 */
export default {
  page: {
    title: "Upload Datafile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    VueTagsInput,
    Multiselect
  },
  data() {
    return {
      loggedInUser: {
        company: {}
      },
      datafileTitle: null,
      datafileKey: null,
      certificateLevel: null,
      datafileHeaderRow: null,
      datafileDataRecordStartRow: null,
      datafileMetaDataArray: null,
      numberOfRespondents: 0,
      missingValuesObjects: [],
      vue_tag: "",
      sheetNames: [],
      selectedSheetName: null,
      // oparational Variables
      displayDuplicateHeadersError: false,
      isStep1Completed: false,
      isClickNextStep: false,
      originalDatafileuploadProcessing: false,
      isOriginalDatafileUploaded: false,
      displayOriginalFileRequiredError: false,
      fileUploadError: false,
      isPageLoading: false
    };
  },
  validations: {
    datafileTitle: { required, maxLength: maxLength(100) },
    datafileHeaderRow: { required },
    datafileDataRecordStartRow: {
      required,
      greterThanHeaderRow
    },
    selectedSheetName: { required }
  },
  computed: {
    items() {
      const items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$t("uploadProcessStep1.pageTitle"),
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    this.isPageLoading = true;
    // getLoggedInUser
    this.loggedInUser = await AuthService.getLoggedInUser();

    if (
      this.loggedInUser.userPreferences.datafileInputObject &&
      this.loggedInUser.userPreferences.isDatafileUploading
    ) {
      this.$router.push({
        path: "./step3"
      });
    } else {
      try {
        const clearLocalStorage = await UserService.getClearLocalStorage();
        if (clearLocalStorage) {
          window.localStorage.removeItem("selectedColumns");
          window.localStorage.removeItem("datafileMetaDataArray");
          window.localStorage.removeItem("workingMetaDataArray");
          window.localStorage.removeItem("datafileInputObject");
          window.localStorage.removeItem("isEnableWrongTopicCheckInUpload");
          window.localStorage.removeItem("enableDuplicateChecksInUpload");
          window.localStorage.removeItem("enablePatternCheckMain");
          window.localStorage.removeItem("enablePatternCheckAdditional");
          await UserService.setClearLocalStorage(false);
        } else {
          // Set values from db to local storage
          let datafileInputObjectFromUserPreferences, selectedColumns;
          if (this.loggedInUser.userPreferences?.datafileInputObject) {
            datafileInputObjectFromUserPreferences = JSON.parse(
              this.loggedInUser.userPreferences.datafileInputObject
            );
          }
          if (this.loggedInUser.userPreferences?.selectedColumns) {
            selectedColumns = JSON.parse(
              this.loggedInUser.userPreferences.selectedColumns
            );
          }
          if (datafileInputObjectFromUserPreferences && selectedColumns) {
            this.setDataToLocalStorage({
              datafileInputObject: datafileInputObjectFromUserPreferences,
              selectedColumns
            });
          }
          await UserService.changeDatafileUploadingProgressStatus({
            selectedColumns: null,
            datafileInputObject: null,
            creditAmount: null
          });
        }
      } catch (e) {
        console.log("Failed to clear local storage.");
      }
    }

    // get Items from local storage and assigne those values to local state
    const datafileInputObjectFromLocalStorage = JSON.parse(
      localStorage.getItem("datafileInputObject")
    );

    const metaDataArrayObjectFromLocalStorage = JSON.parse(
      localStorage.getItem("datafileMetaDataArray")
    );

    if (metaDataArrayObjectFromLocalStorage) {
      this.datafileMetaDataArray = metaDataArrayObjectFromLocalStorage;
    }

    if (datafileInputObjectFromLocalStorage) {
      this.isClickNextStep = true;
      this.isOriginalDatafileUploaded = true;
      this.isStep1Completed = true;

      this.datafileTitle = datafileInputObjectFromLocalStorage.title;
      this.datafileKey = datafileInputObjectFromLocalStorage.datafileKey;
      this.datafileHeaderRow =
        datafileInputObjectFromLocalStorage.metadata.headerRow;
      this.datafileDataRecordStartRow =
        datafileInputObjectFromLocalStorage.metadata.dataRecordStartRow;
      this.selectedSheetName = datafileInputObjectFromLocalStorage.metadata.sheetName;
      this.numberOfRespondents =
        datafileInputObjectFromLocalStorage.numberOfRespondents;
      this.missingValuesObjects =
        datafileInputObjectFromLocalStorage.metadata.missingValuesObjects;
    }

    this.isPageLoading = false;
  },
  methods: {
    setDataToLocalStorage({ datafileInputObject, selectedColumns }) {
      localStorage.setItem(
        "datafileInputObject",
        JSON.stringify(datafileInputObject)
      );
      localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
    },
    validateForm() {
      this.$v.$touch();

      // check file is uploaded or not
      if (this.isOriginalDatafileUploaded) {
        if (
          !this.isStep1Completed && hasDuplicates(this.datafileMetaDataArray)
        ) {
          this.isClickNextStep = false;
          this.displayDuplicateHeadersError = true;
          return false;
        }

        return !this.$v.$invalid ? true : false;
      } else {
        this.isClickNextStep = false;
        this.displayOriginalFileRequiredError = true;
      }
    },
    async uploadFile(ev) {
      this.fileUploadError = false;
      this.displayDuplicateHeadersError = false;
      this.originalDatafileuploadProcessing = true;
      // read file and create array from the file
      // const self = this;
      const file = ev.target.files[0];
      const surveyFileName = file.name;
      const fileNameString = surveyFileName.split(".");
      const fileType = fileNameString[fileNameString.length - 1];
      const fileName = fileNameString[0];
      let getFileUploadResponse = null;
      // set filekey with the timestamp
      const tempDatafileKey =
        fileName.trim() + "-T" + new Date().getTime() + "Z." + fileType;

      // get Pre Signed URL for XLSX File
      let XLSXPreSignedURL = await S3Service.getDatafilePreSignedURL(
        tempDatafileKey
      );
      let fileContent;
      function readFile(_file) {
        return new Promise((resolve, reject) => {
          var reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.onerror = (e) => reject(e);
          reader.readAsArrayBuffer(_file);
        });
      }
      try {
        fileContent = await readFile(file);
      } catch (e) {
        console.error(e);
        this.fileUploadError = true;
      }
      if (XLSXPreSignedURL) {
        // Upload XLSX file to S3
        getFileUploadResponse = await axios.put(
          XLSXPreSignedURL.signedUrl,
          fileContent
        );
      } else {
        this.fileUploadError = true;
      }

      // is axios.put work assign file name to file URL
      if (getFileUploadResponse) {
        this.datafileKey = tempDatafileKey;
        this.sheetNames = await DatafileService.getSheetNames(this.datafileKey, this.loggedInUser.company.id);
        this.originalDatafileuploadProcessing = false;
        this.isOriginalDatafileUploaded = true;
        this.displayOriginalFileRequiredError = false;
      }
    },
    async clickOnNextStep() {
      this.isClickNextStep = true;
      const { headerRowPayload, numberOfRespondents } = await DataImportService.getDatafileHeaderRow({
        datafileKey: this.datafileKey,
        headerRow: Number(this.datafileHeaderRow),
        dataRecordStartRow: Number(this.datafileDataRecordStartRow),
        sheetName: this.selectedSheetName
      });
      this.datafileMetaDataArray = headerRowPayload;
      this.numberOfRespondents = numberOfRespondents;
      let isValidationSuccess = this.validateForm();
      if (isValidationSuccess) {
        // Create Datafile Input Object
        const newDatafileInputObject = new datafileInputObject();
        newDatafileInputObject.title = this.datafileTitle;
        newDatafileInputObject.datafileKey = this.datafileKey;
        newDatafileInputObject.metadata.headerRow = Number(
          this.datafileHeaderRow
        );
        newDatafileInputObject.metadata.dataRecordStartRow = Number(
          this.datafileDataRecordStartRow
        );
        newDatafileInputObject.metadata.sheetName = this.selectedSheetName;
        newDatafileInputObject.numberOfRespondents = this.numberOfRespondents;

        let missingValues = [];
        for (let i = 0; i < this.missingValuesObjects.length; i++) {
          missingValues.push(this.missingValuesObjects[i].text);
        }
        newDatafileInputObject.metadata.missingValues = missingValues;
        newDatafileInputObject.metadata.missingValuesObjects =
          this.missingValuesObjects;

        // set items to local storage
        localStorage.setItem(
          "datafileInputObject",
          JSON.stringify(newDatafileInputObject)
        );
        localStorage.setItem(
          "datafileMetaDataArray",
          JSON.stringify(this.datafileMetaDataArray)
        );

        // redirect to stpe 2
        this.$router.push("/upload-dataset/step2");
      } else {
        this.isClickNextStep = false;
      }
    },
    async clickOnResetProcess() {
      let removeFileFroms3Success =
        await S3Service.removeOriginalDatafileFromS3(this.datafileKey);

      if (removeFileFroms3Success.status) {
        // reset all local variables (make all varibales to initial state)
        this.datafileTitle = null;
        this.datafileKey = null;
        this.datafileHeaderRow = null;
        this.datafileDataRecordStartRow = null;
        this.selectedSheetName = null;
        this.originalDatafileuploadProcessing = false;
        this.isOriginalDatafileUploaded = false;
        this.isClickNextStep = false;
        this.isStep1Completed = false;
        this.missingValuesObjects = [];

        // remove items from local storage
        window.localStorage.removeItem("datafileMetaDataArray");
        window.localStorage.removeItem("datafileInputObject");
        window.localStorage.removeItem("workingMetaDataArray");
        window.localStorage.removeItem("selectedColumns");
        window.localStorage.removeItem("isEnableWrongTopicCheckInUpload");
      }
    },
    async removeOnlyDataset() {
      let removeFileFroms3Success =
        await S3Service.removeOriginalDatafileFromS3(this.datafileKey);

      if (removeFileFroms3Success.status) {
        // reset local variables (make all varibales to initial state)
        this.datafileKey = null;
        this.datafileHeaderRow = null;
        this.datafileDataRecordStartRow = null;
        this.originalDatafileuploadProcessing = false;
        this.isOriginalDatafileUploaded = false;
        this.isClickNextStep = false;
        this.isStep1Completed = false;
        this.missingValuesObjects = [];

        // remove items from local storage
        window.localStorage.removeItem("datafileMetaDataArray");
        window.localStorage.removeItem("datafileInputObject");
        window.localStorage.removeItem("workingMetaDataArray");
        window.localStorage.removeItem("selectedColumns");
      }
    },
    clickOnContact() {
      let sub = "Activate the Credit Plan of " + this.loggedInUser.company.name;
      window.location.assign("mailto:business@redem.io?Subject=" + sub);
    },
    tagsGetChange(newTags) {
      this.missingValuesObjects = [];
      for (let i = 0; i < newTags.length; i++) {
        newTags[i].style = "color: #fff; background-color: #005181;";

        this.missingValuesObjects.push(newTags[i]);
      }
    },
    goBackToDashboard() {
      this.$router.push("/");
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('uploadProcessStep1.pageTitle')" :items="items" />
    <div v-if="!isPageLoading">
      <!-- Contract Running -->
      <div
        class="row"
        v-if="this.loggedInUser.company.contractStatus === 'RUNNING'"
      >
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <!-- Card Title -->
                <div class="col-md-6 text-left mb-5">
                  <h5>{{ $t("uploadProcessStep1.form.title") }}</h5>
                </div>

                <!-- Reset Button -->
                <div class="col-md-6 text-right mb-5">
                  <div v-if="isStep1Completed && isOriginalDatafileUploaded">
                    <b-button variant="warning" @click="clickOnResetProcess()">
                      {{ $t("uploadProcessStep1.form.resetButton") }}
                      <i class="mdi mdi-autorenew"></i>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <!-- Do not Delete -->
                <div class="col-md-3"></div>

                <!-- Form Component -->
                <div class="col-md-6">
                  <div class="row">
                    <!-- Datafile Title -->
                    <div class="col-12">
                      <div class="form-group mb-5">
                        <label for="datafileTitle">
                          {{ $t("uploadProcessStep1.form.name.label") }}
                          <span class="text-danger">*</span>
                          <span>
                            <p
                              v-if="datafileTitle"
                              class="badge position-absolute ml-2"
                              :class="{
                                'bg-light': datafileTitle.length !== 100,
                                'bg-danger': datafileTitle.length === 100
                              }"
                            >
                              {{ datafileTitle.length }} / 100
                            </p>
                          </span>
                        </label>
                        <input
                          v-model.trim="datafileTitle"
                          id="datafileTitle"
                          type="text"
                          class="form-control"
                          name="datafileTitle"
                          :placeholder="
                            $t('uploadProcessStep1.form.name.placeholder')
                          "
                          :class="{ 'is-invalid': $v.datafileTitle.$error }"
                        />
                        <div
                          v-if="$v.datafileTitle.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              $v.datafileTitle.$error &&
                              !$v.datafileTitle.required
                            "
                            >{{
                              $t(
                                "uploadProcessStep1.form.name.requiredErrorMsg"
                              )
                            }}</span
                          >
                          <span
                            v-if="
                              $v.datafileTitle.$error &&
                              !$v.datafileTitle.maxLength
                            "
                            >{{
                              $t(
                                "uploadProcessStep1.form.name.maxLengthErrorMsg"
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- Datafile File -->
                    <div class="col-8">
                      <div class="form-group mb-5">
                        <label>
                          {{ $t("uploadProcessStep1.form.fileSelector.label") }}
                          <span class="text-danger">*</span>
                          <span
                            v-if="
                              originalDatafileuploadProcessing &&
                              !fileUploadError
                            "
                          >
                            <i class="bx bx-loader-circle bx-spin ml-2"></i>
                          </span>
                          <span
                            class="ml-3 text-info pointer"
                            v-if="isOriginalDatafileUploaded"
                            @click="removeOnlyDataset()"
                          >
                            <i class="mdi mdi-close-circle-outline mr-1"></i>
                            {{
                              $t(
                                "uploadProcessStep1.form.fileSelector.removeLabel"
                              )
                            }}
                          </span>
                        </label>
                        <div
                          class="custom-file"
                          v-if="!isOriginalDatafileUploaded"
                        >
                          <b-form-file
                            id="originalDatafile"
                            ref="originalDatafile"
                            :placeholder="
                              $t(
                                'uploadProcessStep1.form.fileSelector.placeholder'
                              )
                            "
                            drop-placeholder="Drop file here..."
                            accept=".xlsx"
                            @change="uploadFile"
                          ></b-form-file>
                          <span
                            class="text-small text-danger"
                            v-if="displayOriginalFileRequiredError"
                            ><small>
                              {{
                                $t(
                                  "uploadProcessStep1.form.fileSelector.requiredErrorMsg"
                                )
                              }}
                            </small></span
                          >
                        </div>
                        <div v-if="isOriginalDatafileUploaded">
                          {{
                            this.datafileKey.split("-T")[0] +
                            "." +
                            this.datafileKey.split("Z.")[1]
                          }}
                        </div>
                        <div class="text-danger mt-2" v-if="fileUploadError">
                          {{
                            $t(
                              "uploadProcessStep1.form.fileSelector.uploadErrorMsg"
                            )
                          }}
                        </div>
                        <div
                          class="text-danger mt-2"
                          v-if="displayDuplicateHeadersError"
                        >
                          {{
                            $t(
                              "uploadProcessStep1.form.fileSelector.duplicateErrorMsg"
                            )
                          }}
                        </div>
                      </div>
                    </div>

                    <!-- Select sheet name -->
                    <div class="col-4">
                      <div class="form-group">
                        <label for="sheetName">
                          {{ $t("uploadProcessStep1.form.sheetSelector.label") }}
                          <span class="text-danger">*</span>
                          <span v-if="(!$v.selectedSheetName.$error && isClickNextStep) || isStep1Completed">
                            <i class="bx bx-check text-success bx-tada"></i>
                          </span>                          
                        </label>
                        <select
                          v-if="isStep1Completed"
                          :disabled="isStep1Completed"
                          class="form-control"
                          style="
                            background: #f5f5f5;
                            -webkit-appearance: none;
                            -moz-appearance: none;
                          "                        
                        >
                          <option>{{ selectedSheetName }}</option>
                        </select>
                        <multiselect
                          v-else
                          v-model="selectedSheetName"
                          :disabled="isStep1Completed"
                          :options="sheetNames"
                          :multiple="false"
                          :close-on-select="true"
                          :clear-on-select="false"             
                          :placeholder="$t('uploadProcessStep1.form.sheetSelector.placeholder')"
                          :class="{ 'is-invalid': $v.selectedSheetName.$error }"
                        ></multiselect>
                        <div
                          v-if="$v.selectedSheetName.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              $v.selectedSheetName.$error && !$v.selectedSheetName.required
                            "
                            >{{
                              $t(
                                "uploadProcessStep1.form.sheetSelector.requiredErrorMsg"
                              )
                            }}
                            </span>
                        </div>
                      </div>
                    </div>

                    <!-- Datafile Header Row -->
                    <div class="col-6">
                      <div class="form-group mb-5">
                        <label for="datafileHeaderRow">
                          {{ $t("uploadProcessStep1.form.headerRow.label") }}
                          <span class="text-danger">*</span>
                          <span v-if="(!$v.datafileHeaderRow.$error && isClickNextStep) || isStep1Completed">
                            <i class="bx bx-check text-success bx-tada"></i>
                          </span>
                        </label>
                        <input
                          v-if="isStep1Completed"
                          :disabled="isStep1Completed"
                          v-model.trim="datafileHeaderRow"
                          id="datafileHeaderRow"
                          type="number"
                          class="form-control"
                          name="datafileHeaderRow"
                          style="background: #f5f5f5"
                          :placeholder="
                            $t('uploadProcessStep1.form.headerRow.placeholder')
                          "
                          :class="{ 'is-invalid': $v.datafileHeaderRow.$error }"
                        />
                        <input
                          v-else
                          v-model.trim="datafileHeaderRow"
                          id="datafileHeaderRow"
                          type="number"
                          class="form-control"
                          name="datafileHeaderRow"
                          :placeholder="
                            $t('uploadProcessStep1.form.headerRow.placeholder')
                          "
                          :class="{ 'is-invalid': $v.datafileHeaderRow.$error }"
                        />
                        <div
                          v-if="$v.datafileHeaderRow.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              $v.datafileHeaderRow.$error &&
                              !$v.datafileHeaderRow.required
                            "
                            >{{
                              $t(
                                "uploadProcessStep1.form.headerRow.requiredErrorMsg"
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <!-- Datafile Data Record Start Row -->
                    <div class="col-6">
                      <div class="form-group mb-5">
                        <label for="datafileDataRecordStartRow">
                          {{ $t("uploadProcessStep1.form.startingRow.label") }}
                          <span class="text-danger">*</span>
                          <span v-if="(!$v.datafileDataRecordStartRow.$error && isClickNextStep) || isStep1Completed">
                            <i class="bx bx-check text-success bx-tada"></i>
                          </span>
                        </label>
                        <input
                          v-if="isStep1Completed"
                          :disabled="isStep1Completed"
                          v-model.trim="datafileDataRecordStartRow"
                          id="datafileDataRecordStartRow"
                          type="number"
                          class="form-control"
                          name="datafileDataRecordStartRow"
                          style="background: #f5f5f5"
                          :placeholder="
                            $t(
                              'uploadProcessStep1.form.startingRow.placeholder'
                            )
                          "
                          :class="{
                            'is-invalid': $v.datafileDataRecordStartRow.$error
                          }"
                        />
                        <input
                          v-else
                          v-model.trim="datafileDataRecordStartRow"
                          id="datafileDataRecordStartRow"
                          type="number"
                          class="form-control"
                          name="datafileDataRecordStartRow"
                          :placeholder="
                            $t(
                              'uploadProcessStep1.form.startingRow.placeholder'
                            )
                          "
                          :class="{
                            'is-invalid': $v.datafileDataRecordStartRow.$error
                          }"
                        />
                        <div
                          v-if="$v.datafileDataRecordStartRow.$error"
                          class="invalid-feedback"
                        >
                          <span
                            v-if="
                              $v.datafileDataRecordStartRow.$error &&
                              !$v.datafileDataRecordStartRow.required
                            "
                            >{{
                              $t(
                                "uploadProcessStep1.form.startingRow.requiredErrorMsg"
                              )
                            }}</span
                          >
                          <span
                            v-if="
                              $v.datafileDataRecordStartRow.$error &&
                              !$v.datafileDataRecordStartRow.greterThanHeaderRow
                            "
                          >
                            {{
                              $t(
                                "uploadProcessStep1.form.startingRow.shouldGraterThanHeaderRow"
                              )
                            }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <!-- Datafile Missing Vlaues -->
                    <div class="col-12">
                      <div class="mb-5">
                        <label>
                          {{ $t("uploadProcessStep1.missingValues.label") }}
                          <span v-if="isClickNextStep">
                            <i class="bx bx-check text-success bx-tada"></i>
                          </span>
                        </label>
                        <vue-tags-input
                          style="max-width: none; width 100%"
                          v-model="vue_tag"
                          :tags="missingValuesObjects"
                          :placeholder="
                            $t('uploadProcessStep1.missingValues.placeholder')
                          "
                          @tags-changed="(newTags) => tagsGetChange(newTags)"
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <!-- Do not Delete -->
                <div class="col-md-3"></div>
              </div>
            </div>
          </div>

          <!-- Next Button -->
          <div class="fixed-bottom justify-content-end static-buttons">
            <div class="card-body col-md-12 text-right">
              <b-button
                variant="primary"
                @click="clickOnNextStep()"
                class="mr-2"
              >
                {{ $t("uploadProcessStep1.form.nextStepButton") }}
                <i class="mdi mdi-chevron-right"></i>
              </b-button>
            </div>
          </div>
        </div>
      </div>

      <!-- Contract PAUSE -->
      <div class="row" v-else>
        <div class="col-md-12 text-center p-5">
          {{ $t("uploadProcessStep1.contractPause.text1") }}
          <br />
          {{ $t("uploadProcessStep1.contractPause.text2") }}
          <br />
          <b-button variant="success" @click="clickOnContact()" class="mt-3">
            <i class="bx bx-envelope font-size-18 mr-2"></i
            >{{ $t("uploadProcessStep1.contractPause.button") }}
          </b-button>
        </div>
      </div>
    </div>

    <!-- Display Page Loading -->
    <div class="row justify-content-center" v-else>
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.pointer {
  cursor: pointer;
}

.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.vue-tags-input .ti-tag {
  position: relative;
  background: #ebde6e;
  color: #283944;
}
</style>
