export function newColumnObject() {
  this.datasetColumnIndex = null;
  this.datasetColumnName = null;
  this.questionTitle = null;
  this.columnType = null;
  this.columnTypeObject = null;
  this.columnSaved = false;
  this.columnSavedCount = 0;
  this.isEnebleColumnEditing = true;
  this.clickedContinueOne = false;
  this.clickedContinueTwo = false;
  this.isAnswerListHavingAnError = false;
  this.answerListError = null;
  this.isAllAnswersGet = false;
  this.numberOfAnswers = 0;
  this.savedPredictionCount = 0;
  this.answersList = null;
  this.predictionsList = [];
  this.qualityScores = {
    IS: false,
    PS: true,
    SDS: false,
    TS: false,
    IBS: false,
    CS: true,
    OES: false
  };
  this.itemBatteryEndRangeName = null;
  this.itemBatteryEndRangeIndex = null;
  this.itemBatteryIndex = 0;
  this.timeStampsList = [];
  this.openEndedList = [];
  this.itemBatteryRanges = [];
  this.itemBatteryColumnNames = [];
  this.groupSelector = [];
  this.languagesToCheck = null;
  this.keywords=[];
  this.keywordsTag ="";
  this.validationInProgress = false;
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  }
  
}

export function newPredictionObject() {
  this.predictionQuestionReferenceIndex = null;
  this.predictionQuestionReferenceTitle = null;
  this.datasetColumnIndex = null;
  this.datasetColumnName = null;
  this.answer = null;
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  };
}

export function newTimeStampObject() {
  this.datasetColumnIndex = null;
  this.datasetColumnName = null;
  this.columnType = "TIMESTAMP";
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  };
}

export function newOpenEndObject() {
  this.datasetColumnIndex = null;
  this.datasetColumnName = null;
  this.questionTitle = null;
  this.columnType = "OPEN_ENDED";
  this.languagesToCheck = null;
  this.keywords=[];
  this.keywordsTag ="",
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  };
}

export function newGroupSelectorObject() {
  this.datasetColumnIndex = null;
  this.datasetColumnName = null;
  this.columnType = "GROUP_SELECTOR";
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  };
}

export function newdataPointMetadata() {
  this.dataPointIdentifier = null;
  this.dataPointColumn = null;
  this.dataPointType = null;
  this.endCol = null;
  this.startCol = null;
  this.predictionColumns = [];
  this.questionTitle = null;
  this.algorithms = {
    IS: false,
    PS: false,
    SDS: false,
    TS: false,
    CS: false,
    OES: false
  };
  this.languagesToCheck = null;
  this.isEnableWrongTopicCheck = false;
  this.enableDuplicateChecks = false;
  this.enablePatternCheck = false;
  this.keywords=[];
}

export function datafileInputObject() {
  this.title = null;
  this.datafileKey = null;
  this.numberOfRespondents = 0;
  this.numberOfQuestions = null;
  this.amountOfCredits = null;
  this.metadata = {
    headerRow: null,
    dataRecordStartRow: null,
    dataRecordIdColumn: null,
    sheetName: null,
    dataPointMetadata: [],
    groupSelectors: []
  };
  this.certificateLevel = null;
  this.eligibleCertificateLevel = null;
  this.isEnableWrongTopicCheck = false
}

export function newItemBatteryRangeObject() {
  this.rangeStartName = null;
  this.rangeStartIndex = 0;
  this.rangeEndName = null;
  this.rangeEndIndex = 0;
  this.columnNames = [];
  this.validationError = {
    isDisplayValidationError: false,
    errors: []
  };
}
